import React, { useState, useEffect } from "react";
import Navbar from "../components/Navbar";
import Hero from "../components/Hero";
import Features from "../components/Features";
import Pricing from "../components/Pricing";
import Faq from "../components/Faq";
import Footer from "../components/Footer";
import InBlog from "../components/InBlog";
import ChatButton from "../components/ChatButton";
import { Helmet } from "react-helmet-async";

function Home() {
  const [showExitPopup, setShowExitPopup] = useState(false);

  useEffect(() => {
	  
	   // Check if the popup was shown today
    const lastShownDate = localStorage.getItem("exitPopupShown");
    const today = new Date().toISOString().split("T")[0]; // YYYY-MM-DD format

    if (lastShownDate === today) {
      return; // Don't show the popup if already shown today
    }

	  
    // Detect user moving cursor to the top of the screen
    const handleMouseLeave = (event) => {
      if (event.clientY <= 0) {
        setShowExitPopup(true);
        localStorage.setItem("exitPopupShown", today); // Save today's date
      }
    };

    // Add event listener
    document.addEventListener("mouseleave", handleMouseLeave);

    // Cleanup event listener on unmount
    return () => {
      document.removeEventListener("mouseleave", handleMouseLeave);
    };
  }, []);
  
  
  return (
    <>
      <Helmet>
        <title>Seotic - Powerful SEO Tools for Small Businesses</title>
        <meta
          name="description"
          content="Seotic provides easy-to-use SEO tools for small businesses to enhance online visibility, drive traffic, and grow your brand. Get started with Seotic today!"
        />
        <meta
          name="keywords"
          content="SEO, SEO tools, small business, digital marketing, online visibility, Seotic"
        />
        <link rel="icon" href="https://www.seotic.co/favicon.ico" />

        {/* Open Graph Meta Tags */}
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.seotic.co" />
        <meta
          property="og:title"
          content="Seotic - SEO Tools for Small Businesses"
        />
        <meta
          property="og:description"
          content="Optimize your website's SEO with Seotic's powerful, user-friendly tools designed for small businesses. Start improving your search rankings today!"
        />
        <meta property="og:image" content="https://www.seotic.co/og-image.jpg" />

        {/* Twitter Card Meta Tags */}
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content="https://www.seotic.co" />
        <meta
          property="twitter:title"
          content="Seotic - SEO Tools for Small Businesses"
        />
        <meta
          property="twitter:description"
          content="Optimize your website's SEO with Seotic's powerful, user-friendly tools designed for small businesses. Start improving your search rankings today!"
        />
        <meta property="twitter:image" content="https://www.seotic.co/og-image.jpg" />
      </Helmet>

      {/* Fullscreen Exit Popup */}
      {showExitPopup && (
        <div className="fixed inset-0 bg-slate-100 flex justify-center items-center z-50">
          <div className="p-8 rounded-lg text-center w-full md:w-1/2">
            <h2 className="text-4xl font-medium mb-4">Hold on! Before you go...</h2>
            <p className="mb-6 text-lg">
               Seotic packed with <span className="font-bold">10 major features</span> and <span className="font-bold">tools</span> to ranking up your website.
            
            </p>
            <div className="grid grid-cols-4 gap-2 mb-10">
            <div className="border rounded-lg  flex flex-col p-8 items-center align-center"><svg className="mb-2" className="mb-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="44" height="44" color="#000000" fill="none">
    <path d="M2.5 8.18677C2.60406 6.08705 2.91537 4.77792 3.84664 3.84664C4.77792 2.91537 6.08705 2.60406 8.18677 2.5M21.5 8.18677C21.3959 6.08705 21.0846 4.77792 20.1534 3.84664C19.2221 2.91537 17.9129 2.60406 15.8132 2.5M15.8132 21.5C17.9129 21.3959 19.2221 21.0846 20.1534 20.1534C21.0846 19.2221 21.3959 17.9129 21.5 15.8132M8.18676 21.5C6.08705 21.3959 4.77792 21.0846 3.84664 20.1534C2.91537 19.2221 2.60406 17.9129 2.5 15.8132" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M2.49986 12H21.4999" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" />
    <path d="M6 12C6 8.68629 8.68629 6 12 6C12 7.65685 13.3431 9 15 9C15.6755 9 16.2989 8.77672 16.8004 8.39993C17.5536 9.40273 18 10.6492 18 12M17.1973 15C16.1599 16.7934 14.2208 18 12 18C9.77915 18 7.84012 16.7934 6.80269 15" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
</svg>Site Audit</div>

<div className="border rounded-lg  flex flex-col p-8 items-center align-center"><svg className="mb-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="44" height="44" color="#000000" fill="none">
    <path d="M9.60059 6.11211C9.92247 5.29596 11.0775 5.29596 11.3994 6.11211L12.3103 8.4216C12.8999 9.91665 14.0833 11.1001 15.5784 11.6897L17.8879 12.6006C18.704 12.9225 18.704 14.0775 17.8879 14.3994L15.5784 15.3103C14.0833 15.8999 12.8999 17.0833 12.3103 18.5784L11.3994 20.8879C11.0775 21.704 9.92247 21.704 9.60059 20.8879L8.68974 18.5784C8.1001 17.0833 6.91665 15.8999 5.4216 15.3103L3.11211 14.3994C2.29596 14.0775 2.29596 12.9225 3.11211 12.6006L5.4216 11.6897C6.91665 11.1001 8.1001 9.91665 8.68974 8.4216L9.60059 6.11211Z" stroke="currentColor" stroke-width="1.5" />
    <path d="M18.1627 2.72954C18.2834 2.42349 18.7166 2.42349 18.8373 2.72954L19.1788 3.5956C19.4 4.15624 19.8438 4.60004 20.4044 4.82115L21.2705 5.16272C21.5765 5.28343 21.5765 5.71657 21.2705 5.83728L20.4044 6.17885C19.8438 6.39996 19.4 6.84376 19.1788 7.4044L18.8373 8.27046C18.7166 8.57651 18.2834 8.57651 18.1627 8.27046L17.8212 7.4044C17.6 6.84376 17.1562 6.39996 16.5956 6.17885L15.7295 5.83728C15.4235 5.71657 15.4235 5.28343 15.7295 5.16272L16.5956 4.82115C17.1562 4.60004 17.6 4.15624 17.8212 3.5956L18.1627 2.72954Z" stroke="currentColor" stroke-width="1.5" />
    
</svg>AI Audit</div>
<div className="border rounded-lg  flex flex-col p-8 items-center align-center"><svg className="mb-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="44" height="44" color="#000000" fill="none">
    <path d="M9.14339 10.691L9.35031 10.4841C11.329 8.50532 14.5372 8.50532 16.5159 10.4841C18.4947 12.4628 18.4947 15.671 16.5159 17.6497L13.6497 20.5159C11.671 22.4947 8.46279 22.4947 6.48405 20.5159C4.50532 18.5372 4.50532 15.329 6.48405 13.3503L6.9484 12.886" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" />
    <path d="M17.0516 11.114L17.5159 10.6497C19.4947 8.67095 19.4947 5.46279 17.5159 3.48405C15.5372 1.50532 12.329 1.50532 10.3503 3.48405L7.48405 6.35031C5.50532 8.32904 5.50532 11.5372 7.48405 13.5159C9.46279 15.4947 12.671 15.4947 14.6497 13.5159L14.8566 13.309" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" />
</svg>Backlinks</div>

<div className="border rounded-lg  flex flex-col p-8 items-center align-center"><svg className="mb-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="44" height="44" color="#000000" fill="none">
    <path d="M8.9835 1.99998C6.17689 2.06393 4.53758 2.33109 3.41752 3.44727C2.43723 4.42416 2.10954 5.79742 2 7.99998M15.0165 1.99998C17.8231 2.06393 19.4624 2.33109 20.5825 3.44727C21.5628 4.42416 21.8905 5.79742 22 7.99998M15.0165 22C17.8231 21.9361 19.4624 21.6689 20.5825 20.5527C21.5628 19.5758 21.8905 18.2026 22 16M8.9835 22C6.17689 21.9361 4.53758 21.6689 3.41752 20.5527C2.43723 19.5758 2.10954 18.2026 2 16" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M15 15L17 17M16 11.5C16 9.01468 13.9853 6.99998 11.5 6.99998C9.01469 6.99998 7 9.01468 7 11.5C7 13.9853 9.01469 16 11.5 16C13.9853 16 16 13.9853 16 11.5Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
</svg>SERP</div>

<div className="border rounded-lg  flex flex-col p-8 items-center align-center"><svg className="mb-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="44" height="44" color="#000000" fill="none">
    <path d="M11.1075 5.57624C11.3692 6.02707 11.5 6.25248 11.5 6.5C11.5 6.74752 11.3692 6.97293 11.1075 7.42376L9.85804 9.57624C9.59636 10.0271 9.46551 10.2525 9.25 10.3762C9.03449 10.5 8.7728 10.5 8.24943 10.5H5.75057C5.2272 10.5 4.96551 10.5 4.75 10.3762C4.53449 10.2525 4.40364 10.0271 4.14196 9.57624L2.89253 7.42376C2.63084 6.97293 2.5 6.74752 2.5 6.5C2.5 6.25248 2.63084 6.02707 2.89253 5.57624L4.14196 3.42376C4.40364 2.97293 4.53449 2.74752 4.75 2.62376C4.96551 2.5 5.2272 2.5 5.75057 2.5L8.24943 2.5C8.7728 2.5 9.03449 2.5 9.25 2.62376C9.46551 2.74752 9.59636 2.97293 9.85804 3.42376L11.1075 5.57624Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M21.1075 11.5762C21.3692 12.0271 21.5 12.2525 21.5 12.5C21.5 12.7475 21.3692 12.9729 21.1075 13.4238L19.858 15.5762C19.5964 16.0271 19.4655 16.2525 19.25 16.3762C19.0345 16.5 18.7728 16.5 18.2494 16.5H15.7506C15.2272 16.5 14.9655 16.5 14.75 16.3762C14.5345 16.2525 14.4036 16.0271 14.142 15.5762L12.8925 13.4238C12.6308 12.9729 12.5 12.7475 12.5 12.5C12.5 12.2525 12.6308 12.0271 12.8925 11.5762L14.142 9.42376C14.4036 8.97293 14.5345 8.74752 14.75 8.62376C14.9655 8.5 15.2272 8.5 15.7506 8.5L18.2494 8.5C18.7728 8.5 19.0345 8.5 19.25 8.62376C19.4655 8.74752 19.5964 8.97293 19.858 9.42376L21.1075 11.5762Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M11.1075 16.5762C11.3692 17.0271 11.5 17.2525 11.5 17.5C11.5 17.7475 11.3692 17.9729 11.1075 18.4238L9.85804 20.5762C9.59636 21.0271 9.46551 21.2525 9.25 21.3762C9.03449 21.5 8.7728 21.5 8.24943 21.5H5.75057C5.2272 21.5 4.96551 21.5 4.75 21.3762C4.53449 21.2525 4.40364 21.0271 4.14196 20.5762L2.89253 18.4238C2.63084 17.9729 2.5 17.7475 2.5 17.5C2.5 17.2525 2.63084 17.0271 2.89253 16.5762L4.14196 14.4238C4.40364 13.9729 4.53449 13.7475 4.75 13.6238C4.96551 13.5 5.2272 13.5 5.75057 13.5L8.24943 13.5C8.7728 13.5 9.03449 13.5 9.25 13.6238C9.46551 13.7475 9.59636 13.9729 9.85804 14.4238L11.1075 16.5762Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
</svg>Competitor</div>



<div className="border rounded-lg  flex flex-col p-8 items-center align-center"><svg className="mb-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="44" height="44" color="#000000" fill="none">
    <path d="M4 10C3.51076 9.96288 3.16925 9.87932 2.88886 9.69117C2.67048 9.54463 2.48298 9.35634 2.33706 9.13703C2 8.63045 2 7.92524 2 6.51483C2 5.10442 2 4.39921 2.33706 3.89263C2.48298 3.67332 2.67048 3.48502 2.88886 3.33849C3.39331 3 4.09554 3 5.5 3H18.5C19.9045 3 20.6067 3 21.1111 3.33849C21.3295 3.48502 21.517 3.67332 21.6629 3.89263C22 4.39921 22 5.10442 22 6.51483C22 7.80256 22 8.50243 21.7435 9" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" />
    <path d="M18.2 18.2L21 21M19.6 13.3C19.6 9.82057 16.7794 7 13.3 7C9.82057 7 7 9.82057 7 13.3C7 16.7794 9.82057 19.6 13.3 19.6C16.7794 19.6 19.6 16.7794 19.6 13.3Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
</svg>Keywords</div>
<div className="border rounded-lg  flex flex-col p-8 items-center align-center"><svg className="mb-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="44" height="44" color="#000000" fill="none">
    <path d="M6.53792 2.32172C6.69664 1.89276 7.30336 1.89276 7.46208 2.32172L8.1735 4.2443C8.27331 4.51403 8.48597 4.72669 8.7557 4.8265L10.6783 5.53792C11.1072 5.69664 11.1072 6.30336 10.6783 6.46208L8.7557 7.1735C8.48597 7.27331 8.27331 7.48597 8.1735 7.7557L7.46208 9.67828C7.30336 10.1072 6.69665 10.1072 6.53792 9.67828L5.8265 7.7557C5.72669 7.48597 5.51403 7.27331 5.2443 7.1735L3.32172 6.46208C2.89276 6.30336 2.89276 5.69665 3.32172 5.53792L5.2443 4.8265C5.51403 4.72669 5.72669 4.51403 5.8265 4.2443L6.53792 2.32172Z" stroke="currentColor" stroke-width="1.5" />
    <path d="M14.4039 9.64136L15.8869 11.1244M6 22H7.49759C8.70997 22 9.31617 22 9.86124 21.7742C10.4063 21.5484 10.835 21.1198 11.6923 20.2625L19.8417 12.1131C20.3808 11.574 20.6503 11.3045 20.7944 11.0137C21.0685 10.4605 21.0685 9.81094 20.7944 9.25772C20.6503 8.96695 20.3808 8.69741 19.8417 8.15832C19.3026 7.61924 19.0331 7.3497 18.7423 7.20561C18.1891 6.93146 17.5395 6.93146 16.9863 7.20561C16.6955 7.3497 16.426 7.61924 15.8869 8.15832L7.73749 16.3077C6.8802 17.165 6.45156 17.5937 6.22578 18.1388C6 18.6838 6 19.29 6 20.5024V22Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
</svg>AI Writer</div>

<div className="border rounded-lg  flex flex-col p-8 items-center align-center"><svg className="mb-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="44" height="44" color="#000000" fill="none">
    <path d="M2.5 12C2.5 7.52166 2.5 5.28249 3.89124 3.89124C5.28249 2.5 7.52166 2.5 12 2.5C16.4783 2.5 18.7175 2.5 20.1088 3.89124C21.5 5.28249 21.5 7.52166 21.5 12C21.5 16.4783 21.5 18.7175 20.1088 20.1088C18.7175 21.5 16.4783 21.5 12 21.5C7.52166 21.5 5.28249 21.5 3.89124 20.1088C2.5 18.7175 2.5 16.4783 2.5 12Z" stroke="currentColor" stroke-width="1.5" />
    <path d="M11.992 12H12.001" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M15.9959 12H16.0049" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M7.9959 12H8.00488" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
</svg>More</div>



           
            </div>

            <a href="https://app.seotic.co/register" title="create account" 
              className="bg-yellow-300 text-yellow-900 rounded-lg hover:bg-yellow-400 text-medium rounded hover:bg-yellow-300 font-medium uppercase px-10 py-3"
             
            >
             OK, Let's Try
            </a>
          </div>
        </div>
      )}

      {/* Main Content */}
      <div className="">
        <Navbar />
        <Hero />
        <Features />
        <Pricing />
        <InBlog />
        <Faq />
        <div className="container mx-auto my-10">
          <ChatButton />
        </div>
        <Footer />
      </div>
    </>
  );
}

export default Home;
