import React, { useState, useEffect } from 'react';

const ChatButton = () => {

   useEffect(() => {
  // Wait for Brevo Conversations API to load
  const checkBrevoAPI = setInterval(() => {
    if (window.BrevoConversations) {
      clearInterval(checkBrevoAPI);
    }
  }, 500);

  return () => clearInterval(checkBrevoAPI);
}, []);

const openChat = () => {
  if (window.BrevoConversations) {
    window.BrevoConversations('openChat', true);
  } else {
    console.error("Brevo Conversations API is not loaded.");
  }
};


  return (
	  <div className="">
	   <div className="container mx-auto">
    <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mx-2 md:mx-0">
    
     <div className="flex flex-col items-start bg-white rounded-md p-10 border border-slate-200">
        <div className="text-2xl">
          Got questions? Reach out to our support team via live chat. We’re here to help!
        </div>
        <div className="flex items-center mt-10 space-x-5">
        <div className="flex items-center bg-yellow-400 w-fit px-3 py-2 rounded-md text-yellow-900 ">
          <svg className="mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="28" height="28" fill="none">
            <path d="M20 9C19.2048 5.01455 15.5128 2 11.0793 2C6.06549 2 2 5.85521 2 10.61C2 12.8946 2.93819 14.9704 4.46855 16.5108C4.80549 16.85 5.03045 17.3134 4.93966 17.7903C4.78982 18.5701 4.45026 19.2975 3.95305 19.9037C5.26123 20.1449 6.62147 19.9277 7.78801 19.3127C8.20039 19.0954 8.40657 18.9867 8.55207 18.9646C8.65392 18.9492 8.78659 18.9636 9 19.0002" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M11 16.2617C11 19.1674 13.4628 21.5234 16.5 21.5234C16.8571 21.5238 17.2132 21.4908 17.564 21.425C17.8165 21.3775 17.9428 21.3538 18.0309 21.3673C18.119 21.3807 18.244 21.4472 18.4938 21.58C19.2004 21.9558 20.0244 22.0885 20.8169 21.9411C20.5157 21.5707 20.31 21.1262 20.2192 20.6496C20.1642 20.3582 20.3005 20.075 20.5046 19.8677C21.4317 18.9263 22 17.6578 22 16.2617C22 13.356 19.5372 11 16.5 11C13.4628 11 11 13.356 11 16.2617Z" stroke="currentColor" stroke-width="1.5" stroke-linejoin="round" />
          </svg>
          <button className="text-md text-sm font-medium uppercase" onClick={openChat}>Chat with Us</button>
         
        </div>
 <a href="https://meet.brevo.com/seotic-seo-tool/30-minute-meeting" title="Seotic Demo">Request a demo</a>
             </div>
             </div>
    
    
      <div className="flex items-center bg-slate-900 text-white rounded-md p-10">
        <div className="text-2xl leading-normal">
          Join our <a className="text-yellow-500 font-bold" href="https://www.facebook.com/share/g/1AzmG6Eh6b/" title="seotic group" target="_blank">private community</a> for SEO tips and laughs! From "how do I rank?" to "what’s Seotic?"
        </div>
      </div>

     
     
     
             
             <div className="flex flex-col items-start bg-white rounded-md p-10 border border-slate-200">
        <div className="text-2xl font-medium mb-5">
         Pro Catalog
        </div>
        <p className="text-slate-500">Shine with Seotic Pro Catalog! Showcase your services and connect with new clients.</p>
        
        <div className="flex items-center bg-green-400 w-fit px-3 py-2 rounded-md text-green-900 mt-10">
          <svg className="mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="28" height="28" fill="none">
             <path d="M2 12C2 7.99306 2 5.98959 3.17157 4.7448C4.34315 3.5 6.22876 3.5 10 3.5H14C17.7712 3.5 19.6569 3.5 20.8284 4.7448C22 5.98959 22 7.99306 22 12C22 16.0069 22 18.0104 20.8284 19.2552C19.6569 20.5 17.7712 20.5 14 20.5H10C6.22876 20.5 4.34315 20.5 3.17157 19.2552C2 18.0104 2 16.0069 2 12Z" stroke="currentColor" stroke-width="1.5" />
    <path d="M6.8881 10.8314C7.82668 10.2831 8.64587 10.504 9.13798 10.856C9.33975 11.0003 9.44064 11.0725 9.5 11.0725C9.55936 11.0725 9.66025 11.0003 9.86202 10.856C10.3541 10.504 11.1733 10.2831 12.1119 10.8314C13.3437 11.551 13.6224 13.925 10.7812 15.9278C10.24 16.3093 9.96942 16.5 9.5 16.5C9.03058 16.5 8.76 16.3093 8.21884 15.9278C5.3776 13.925 5.65632 11.551 6.8881 10.8314Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" />
    <path d="M18 16.5H15" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
          </svg>
          <button className="text-md text-sm font-medium uppercase">Coming Soon</button>
        </div>

             </div>
    
			 </div>
             </div>
    </div>
  );
};

export default ChatButton;
